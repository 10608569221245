'use strict';

angular.module('windmanagerApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('site', {
        url: '/site/:id',
        template: '<site></site>',
        authenticate: true,
        data: {
          bodyClass: ''
        }
      });
  });
